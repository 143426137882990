<template>
  <div>
    <nav  v-if="isUserLogged" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
    <a class="navbar-brand">Admin</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/dashboard"><b>Dashboard</b></a>
        </li>
      </ul>
    </div>
    <form class="d-flex">
      <button class="btn btn-outline-success" @click="logout" >Logout</button>
    </form>
    </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<style>
#app {
  
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          //alert("Successfully logged out");
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
  computed: {
    isUserLogged: function () {
      console.log("isUserLogged");
      return this.$store.getters.isUserLogged;
    },
  },
};
</script>
