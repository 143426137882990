import { createStore } from "vuex";
import firebase from "firebase";



function getDataCity(collection,city){
  return new Promise((resolve, reject)=> {
    var db = firebase.firestore();
    db.collection(collection).where("cities", "array-contains", city).orderBy("createdAt", "desc")
    .get()
    .then((querySnapshot) => {
      let data = []
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          let item = {...doc.data(),id:doc.id}
          data.push(item)
      });
      resolve(data)
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      reject(error)
    });
  })
}

function insertDataCity(collection, city, data){
  console.log("insertDataCity",city, data)
  return new Promise((resolve, reject)=> {
    var db = firebase.firestore();
    db.collection(collection).doc(city).set({
      ...data,
      createdAt : firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(() => {
        console.log("Document written");
        resolve({[city]:data})
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
        reject(error)
    })
  })
}

export default createStore({
  state: {
    auth: {
      status: "NotAuthenticated",
      //status:firebase.auth().currentUser?'Authenticated':'NotAuthenticated',
      username: null,
      isUserLogged: false,
      //isUserLogged:firebase.auth().currentUser?true:false,
    },
    cities: ["Bologna", "Ferrara", "Rimini", "Ravenna", "Cesena", "Modena", "Trieste", "Padova", "Pesaro"],
    db:[],
    allNews:[],
    allQuizzes:[]
  },
  mutations: {
    auth_checked(state,currentUser) {
      state.auth.status = currentUser?'Authenticated':'NotAuthenticated';
      state.auth.username = currentUser?currentUser.email:null;
      state.auth.isUserLogged = currentUser?true:false;
    },
    auth_request(state) {
      state.auth.status = "Loading";
    },
    auth_success(state, username) {
      state.auth.status = "Authenticated";
      state.auth.username = username;
      state.auth.isUserLogged = true;
    },
    auth_error(state) {
      state.auth.status = "Error";
    },
    logout(state) {
      state.auth.status = "NotAuthenticated";
      state.auth.username = null;
      state.auth.isUserLogged = false;
    },
    dbInsertNews(state, news) {
      console.log("dbInsertNews:",news)
      for (const city of news.cities) {
        if (!(city in state.db)) state.db[city] = {}
        state.db[city].lastNews = news
      }
    },
    dbGetNews(state,{city, news}){
      console.log("Mutation:",city,news)
      if (!(city in state.allNews)) state.allNews[city] = []
        state.allNews[city] = news
        console.log("Mutation",news,state.allNews[city])
    },
    dbDeleteNews(state,id){
        console.log(state,id)
    },
    dbInsertQuiz(state, data) {
      console.log("dbInsertQuiz:",data)
      for (const city of data.cities) {
        if (!(city in state.db)) state.db[city] = {}
        state.db[city].lastQuiz = data
      }
    },
    dbGetQuizzes(state,{city, data}){
      console.log("Mutation:",city,data)
      if (!(city in state.allQuizzes)) state.allQuizzes[city] = []
        state.allQuizzes[city] = data
        console.log("Mutation",data,state.allQuizzes[city])
    },
    dbDeleteQuiz(state,id){
        console.log(state,id)
    },
    dbPublish(state,cities){
       console.log(state,cities)
    }
  },
  getters: {
    isUserLogged: (state) => state.auth.isUserLogged,
    userEmail: (state) => state.auth.username,
    authStatus: (state) => state.auth.status,
    cities: (state) => state.cities,
    allNews: (state) => state.allNews,
    allQuizzes: (state) => state.allQuizzes   
  },
  actions: {
    checkAuth({commit},user){
      console.log("checkAuth",user)
      commit("auth_checked",user)
    },
    async login({ commit }, user) {
      const persistence = firebase.auth.Auth.Persistence.LOCAL;
      /*rememberMe
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION;
        */
      await firebase.auth().setPersistence(persistence);

      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password)
          .then(() => {
            commit("auth_success", user.email);
            resolve(true);
          })
          .catch((error) => {
            commit("auth_error");
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            commit("logout");
            resolve(true);
          })
          .catch((error) => {
            // An error happened.
            reject(error);
          });
      });
    },
    insertNews({ commit },news) {
      console.log("ok inserisco nel db",news)
      return new Promise((resolve, reject)=> {
        var db = firebase.firestore();
        db.collection("news_tmp").add({
          ...news,
          createdAt : firebase.firestore.FieldValue.serverTimestamp()
        })
        .then((docRef) => {
            commit("dbInsertNews",news)
            console.log("Document written with ID: ", docRef.id);
            resolve(docRef.id)
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            reject(error)
        });
      })
    },
    deleteNews({ commit },id) {
      console.log("ok cancello nel db",id)
      return new Promise((resolve, reject)=> {
        var db = firebase.firestore();
        db.collection("news_tmp").doc(id).delete()
        .then(() => {
            commit("dbDeleteNews",id)
            resolve(id)
        })
        .catch((error) => {
            console.error("Error delete document: ", error);
            reject(error)
        });
      })
    },

    getNews({commit}, city){
      console.log("Prelevo news di ",city)
      return new Promise((resolve, reject)=> {
        var db = firebase.firestore();
        db.collection("news_tmp").where("cities", "array-contains", city).orderBy("createdAt", "desc")
        .get()
        .then((querySnapshot) => {
          let data = []
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              let item = {...doc.data(),id:doc.id}
              data.push(item)
          });
          console.log("from store:", data)
          commit("dbGetNews",{city:city, news:data})
          console.log("from store2:", data)
          
          resolve(data)
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          reject(error)
        });
      });
    },
    insertQuiz({ commit },data) {
      console.log("ok inserisco nel db",data)
      data.correct_answer =  data.correct_answer || "1"
      return new Promise((resolve, reject)=> {
        var db = firebase.firestore();
        db.collection("quizzes_tmp").add({
          ...data,
          createdAt : firebase.firestore.FieldValue.serverTimestamp()
        })
        .then((docRef) => {
            commit("dbInsertQuiz",data)
            console.log("Document written with ID: ", docRef.id);
            resolve(docRef.id)
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            reject(error)
        });
      })
    },
    deleteQuiz({ commit },id) {
      console.log("ok cancello nel db",id)
      return new Promise((resolve, reject)=> {
        var db = firebase.firestore();
        db.collection("quizzes_tmp").doc(id).delete()
        .then(() => {
            commit("dbDeleteQuiz",id)
            resolve(id)
        })
        .catch((error) => {
            console.error("Error delete document: ", error);
            reject(error)
        });
      })
    },
    getQuizzes({commit}, city){
      console.log("Prelevo quiz di ",city)
      return new Promise((resolve, reject)=> {
        var db = firebase.firestore();
        db.collection("quizzes_tmp").where("cities", "array-contains", city).orderBy("createdAt", "desc")
        .get()
        .then((querySnapshot) => {
          let data = []
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              let item = {...doc.data(),id:doc.id}
              data.push(item)
          });
          console.log("from store:", data)
          commit("dbGetQuizzes",{city:city, data:data})          
          resolve(data)
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          reject(error)
        });
      });
    },
    publish({commit}, cities){
      console.log("Aggiorno pubblicazione di:", cities)
      commit("dbPublish",{cities})
      for (const city of cities){
        getDataCity('news_tmp',cities[0]).then(data=>{
          //let city = cities[0]
          let news = {}
          let indexNews = 0 
          for(const row of data) {
            let label = "news_"+(indexNews+1)
            news[label] = {title:row['title'],body:row["body"],url:row["url"],createdAt:row["createdAt"]}
            indexNews++
          }
          getDataCity('quizzes_tmp',cities[0]).then(data=>{
            let quizzes = {}
            let indexQuiz = 0 
            for(const row of data) {
              let label = "quiz_"+(indexQuiz+1)
              quizzes[label] = row
              indexQuiz++
            }
            let allData = {
              news:
                { ...news,
                  newsCount:indexNews
                },
              quizzes:{
                ...quizzes,
                quizzesCount:indexQuiz
              }  
            }
            allData =JSON.parse(JSON.stringify(allData))
            insertDataCity("dataCityGlobal",city,Object.assign({},allData)).then().catch() 
            console.log("news:",JSON.stringify(allData, null, 2))
          }).catch(error=>console.error(error))
        }).catch(error=>console.error(error))
       }
     }
  },
  modules: {},
});
