import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!firebase.auth().currentUser) {
        next();
      } else {
        console.log("utente già loggato vai alla dashboard");
        next({ path: "/dashboard" });
      }
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/news/:city",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/News.vue"),
    props:true,
      meta: {
      authRequired: true,
    },
  },
  {
    path: "/quizzes/:city",
    name: "City",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Quizzes.vue"),
    props:true,
      meta: {
      authRequired: true,
    }
  },
  {
      path: "/quizzes/:city",
      name: "Quizzes",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Quizzes.vue"),
      props:true,
        meta: {
        authRequired: true,
      },
    },
    {
        path: "/users/:city",
        name: "Users",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Users.vue"),
        props:true,
          meta: {
          authRequired: true,
      },      
    },
    {
      path: "/stats/:city",
      name: "Stats",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/Stats.vue"),
      props:true,
        meta: {
        authRequired: true,
    },      
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("ROUTE BEFORE EACH", to, from)
  if (to.matched.some((record) => record.meta.authRequired)) {
    console.log("route before: is Logged",store.getters.isUserLogged); 
    if (store.getters.isUserLogged) {
      console.log("ROUTE: isUSerlogged")
      next();
      return
    } else {
      //alert("You must be logged in to see this page");
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});
export default router;
