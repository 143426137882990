import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
//import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import firebase from "firebase";
// Initialize Firebase
// get from cmd line:
// $ firebase apps:sdkconfig
firebase.initializeApp({
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID

});
let app = null
firebase.auth().onAuthStateChanged(async (user) => {
  await store.dispatch("checkAuth", user?user.email:null);
  if (!app){
    app = createApp(App).use(router).use(store).mount('#app')
  }
});
