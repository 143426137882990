<template>
  <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
    <form @submit.prevent="login">
      <h2>Login</h2>

      <input
        type="email"
        placeholder="Email address..."
        v-model="email"
      /><br />
      <input
        type="password"
        placeholder="password..."
        v-model="password"
      /><br /><br />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  setup: function(){
    const email = null
    const password = null
    return {
      email,password
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", { email: this.email, password: this.password })
        .then(() => {
          //alert("Successfully logged in");
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
};
</script>
